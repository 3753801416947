
import './App.css';
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from './components/router';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Social from './components/Social';
import PlaceHolder from './components/PlaceHolder';
import MobileNavbar from './components/MobileNavbar';
import useWindowSize from './components/utils/useWindowSize';
import checkDevice from './components/utils/checkDevice';
import OuterFooter from './components/OuterFooter'
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, [])

  const size = useWindowSize();
  const isMobile = checkDevice(size)

  //Work in progress
  const WIP = true;

  if (WIP) {
    return (
      <>
        {isMobile ? '' : <Social />}
        <PlaceHolder />
        {isMobile ? <Social /> : ''}
        <OuterFooter />
      </>
    )
  } else {
    return (
      <>
        {isMobile ? '' : <Social />}
        {isMobile ? <MobileNavbar /> : <Navbar />}
        <RouterProvider router={router} />
        <Footer />
        {isMobile ? <Social /> : ''}
        <OuterFooter />
      </>
    )
  }
}

export default App;
