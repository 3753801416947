import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function ServicesForm(props) {
    const handleBackButton = props.handleBackButton;
    const handleServicesCheckboxesChange = props.handleServicesCheckboxesChange;
    const servicesSelected = props.servicesSelected;
    return (
        <div className='form-container'>
            <div className='btn-section back'>
                <button onClick={handleBackButton}><FontAwesomeIcon icon={faArrowLeft} />&nbsp; Retour</button>
            </div>
            <div className='input-section'>
                <label className='checkboxes-title'>Comment pouvons-nous vous aider?</label>
                <div className='checkbox-section'>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="create-concept">Création du concept</label>
                        {servicesSelected.includes("create-concept") ? <input type="checkbox" value="create-concept" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="create-concept" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="budget">Budgetisation</label>
                        {servicesSelected.includes("budget") ? <input type="checkbox" value="budget" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="budget" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="caisse">Plan de décaissements et paiements</label>
                        {servicesSelected.includes("caisse") ? <input type="checkbox" value="caisse" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="caisse" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="cordination">Coordination générale</label>
                        {servicesSelected.includes("cordination") ? <input type="checkbox" value="cordination" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="cordination" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="recrutement">Recrutement et négociations prestataires</label>
                        {servicesSelected.includes("recrutement") ? <input type="checkbox" value="recrutement" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="recrutement" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="communication">Gestion de la communication</label>
                        {servicesSelected.includes("communication") ? <input type="checkbox" value="communication" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="communication" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="deco">Aménagement et décoration</label>
                        {servicesSelected.includes("deco") ? <input type="checkbox" value="deco" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="deco" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="gest-accueil">Gestion accueil et hotesses</label>
                        {servicesSelected.includes("gest-accueil") ? <input type="checkbox" value="gest-accueil" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="gest-accueil" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="photo-vid">Couverture videos et photos</label>
                        {servicesSelected.includes("photo-vid") ? <input type="checkbox" value="photo-vid" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="photo-vid" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="event">Execution de l'évènemment</label>
                        {servicesSelected.includes("event") ? <input type="checkbox" value="event" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="event" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="travel">Gestion logistique voyage et hébergement</label>
                        {servicesSelected.includes("travel") ? <input type="checkbox" value="travel" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="travel" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="technique">Gestion technique</label>
                        {servicesSelected.includes("technique") ? <input type="checkbox" value="technique" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="technique" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="traiteur">Gestion et proposition traiteur</label>
                        {servicesSelected.includes("traiteur") ? <input type="checkbox" value="traiteur" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="traiteur" onChange={handleServicesCheckboxesChange} />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="autres">Autres services</label>
                        {servicesSelected.includes("autres") ? <input type="checkbox" value="autres" onChange={handleServicesCheckboxesChange} checked /> : <input type="checkbox" value="autres" onChange={handleServicesCheckboxesChange} />}
                    </div>
                </div>
            </div>
        </div>
  )
}
