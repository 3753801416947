import React from 'react'

export default function News() {
  return (
    <div className='news'>
      <h1>News</h1>
      <section className='section1'>
        <h4>Articles & Presse</h4>
        <div className='container'>

          </div>
      </section>
      <section className='section2'>
        <h4>Actualité</h4>
        <div className='container'>

        </div>
      </section>
      <section className='section3'>
        <h4>News Letter</h4>
        <div className='container'>

        </div>
      </section>
    </div>
  )
}
