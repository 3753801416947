import React from 'react'
import useWindowSize from './utils/useWindowSize'
import checkDevice from './utils/checkDevice'

function OuterFooter() {
  const size = useWindowSize();
  const isMobile = checkDevice(size);

  return (
    <div className={isMobile ? 'mobile-outer-footer outer-footer' : 'outer-footer'}>
      {/* <span className='copyright'>&copy;2023 Copyright The bee, by <a href="https://www.yannickmakwenge.com/" target="_blank" rel="noreferrer">Yannick Makwenge.</a></span> */}
      <span className='copyright'>&copy;2024 The Bee RDC. Tous droits réservés, powered by <a href="https://www.yannickmakwenge.com/" target="_blank" rel="noreferrer">YBM</a></span>
    </div>
  )
}
export default OuterFooter;