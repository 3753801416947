import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react'
import ContactForm from './ContactForm.js';
import ServicesForm from './ServicesForm.js';
import EventForm from './EventForm.js';
import SubmitForm from './SubmitForm.js';

const slides = ["contact", "event", "services","submit"];
export default class Formulaire extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            entreprise: "",
            eventName: "",
            eventDate: "",
            eventDesc: "",
            eventAddress: "",
            eventTypes: [],
            servicesSelected: [],
            contactSlideCompleted: false,
            eventSlideCompleted: false,
            servicesSlideCompleted: false,
            emptyField: false,
            index: 0,
            currentSlide: slides[0]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTypesCheckboxesChange = this.handleTypesCheckboxesChange.bind(this);
        this.moveToNextSlide = this.moveToNextSlide.bind(this);
        this.handleServicesCheckboxesChange = this.handleServicesCheckboxesChange.bind(this);
    }
    resetStates = () => {
        this.setState({
            name: "",
            email: "",
            phone: "",
            entreprise: "",
            eventName: "",
            eventDate: "",
            eventDesc: "",
            eventAddress: "",
            eventTypes: [],
            servicesSelected: [],
            contactSlideCompleted: false,
            eventSlideCompleted: false,
            servicesSlideCompleted: false,
            emptyField: false,
            index: 0,
            currentSlide: slides[0]
        })
    }

    handleChange(event) {
        this.setState({ emptyField: false })
        this.setState({
            [event.target.name]: event.target.value
        }, () => console.log(this.state))
    }

    checkForContactEmptyField() {
        const { name, email, phone } = this.state
        if (name === "" || email === "" || phone === "") {
            return false
        } else {
            return true
        }
    }

    checkForEventEmptyField() {
        const { eventName, eventDate, eventDesc } = this.state;
        if (eventName === "" || eventDate === "" || eventDesc === "") {
            return false
        } else {
            return true
        }
    }

    handleBackButton = (event) => {
        event.preventDefault();
        const size = slides.length;
        const i = this.state.index;
        const currentSlide = this.state.currentSlide;
        console.log(currentSlide)
        
        if (slides.indexOf(currentSlide) !== 0) {
            this.setState({
                index: i-1
            }, () => this.setState({ currentSlide: slides[this.state.index] }))
        } else {
            this.setState({
                index: size-1
            }, () => this.setState({ currentSlide: slides[this.state.index] }))
        }
    }

    moveToNextSlide = (currentSlide) => {
        const i = this.state.index;
        const size = slides.length;
        
        if (slides.indexOf(currentSlide) !== size - 1) {
            this.setState({
                index: i+1
            }, () => this.setState({ currentSlide: slides[this.state.index] }))
        } else {
            this.setState({
                index: 0
            }, () => this.setState({ currentSlide: slides[this.state.index] }))
        }
    };

    handleNext = (event) => {
        event.preventDefault()
        const currentSlide = this.state.currentSlide;

        if (currentSlide === 'contact') {
            if (!this.checkForContactEmptyField()) {
                this.setState({ emptyField: true }, () => window.scrollBy(0, 100))
            } else {
                this.setState({
                    contactSlideCompleted: true
                }, () => this.moveToNextSlide(currentSlide));
            }
        } else if (currentSlide === 'event') {
            if (!this.checkForEventEmptyField()) {
                this.setState({ emptyField: true }, () => window.scrollBy(0, 100))
            } else {
                this.setState({
                    eventSlideCompleted: true
                }, () => this.moveToNextSlide(currentSlide));
            }
        } else if (currentSlide === 'services') {
            this.setState({
                servicesSlideCompleted: true
            }, () => this.moveToNextSlide(currentSlide));
        } else if (currentSlide === 'submit') {
            this.setState({
            }, () => this.moveToNextSlide(currentSlide));
        } 
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { contactSlideCompleted, eventSlideCompleted, servicesSlideCompleted } = this.state
        if (contactSlideCompleted & eventSlideCompleted & servicesSlideCompleted) {
            this.resetStates();
            window.alert("Success");
        } else {
            window.alert("Tous les champs obligatoires n'ont pas été complétés");
        }
    }

    handleSlideNavigation = (e) => {
        e.preventDefault()
        console.log(e.target.getAttribute('value'))
        this.setState({
            currentSlide: e.target.getAttribute('value')
        })
    }

    handleTypesCheckboxesChange = (e) => {
        const eventTypes = this.state.eventTypes;
        //console.log(e.target.checked);
        if (e.target.checked) {
            this.setState({
                eventTypes: [...eventTypes, e.target.value]
            }, () => console.log(eventTypes))

        } else {
            this.setState({
                eventTypes: eventTypes.filter(type => type !== e.target.value)
            }, () => console.log(eventTypes))
        }

    };

    handleServicesCheckboxesChange = (e) => {
        const servicesSelected = this.state.servicesSelected;
        //console.log(e.target.checked);
        if (e.target.checked) {
            this.setState({
                servicesSelected: [...servicesSelected, e.target.value]
            }, () => console.log(servicesSelected))

        } else {
            this.setState({
                servicesSelected: servicesSelected.filter(type => type !== e.target.value)
            }, () => console.log(servicesSelected))
        }
    }

    render() {
        const { name, email, phone, entreprise, servicesSelected,eventAddress, eventDate, eventName,  currentSlide, emptyField, eventDesc, eventTypes} = this.state;
        return (
            <div className='formulaire'>
                <section className='section1'>
                    <h4><span>Formulaire de demande de prestation de service</span></h4>
                    <div className='container'>
                            <div className='tab-section'>
                                <ul>
                                    {currentSlide === 'contact' ? <li className='current-slide' value='contact' onClick={this.handleSlideNavigation}>Détails personnels</li> : <li>Détails personnels</li>}
                                    {currentSlide === 'event' ? <li className='current-slide' value='event' onClick={this.handleSlideNavigation}>Détails de l'évènement</li> : <li>Détails de l'évènement</li>}
                                    {currentSlide === 'services' ? <li className='current-slide' value='services' onClick={this.handleSlideNavigation}>Sélection des services</li> : <li>Sélection des services</li>}
                                    {currentSlide === 'submit' ? <li className='current-slide' value='submit' onClick={this.handleSlideNavigation}>Finalisation</li> : <li>Finalisation</li>}
                                </ul>
                            </div>
                            {currentSlide === 'contact' ?
                                <ContactForm
                                    handleChange={this.handleChange}
                                    name={name}
                                    email={email}
                                    telephone={phone}
                                    entreprise={entreprise} /> :
                                ""}
                            {currentSlide === 'event' ?
                                <EventForm
                                    handleBackButton={this.handleBackButton}
                                    handleChange={this.handleChange}
                                    eventName={eventName}
                                    eventAddress={eventAddress}
                                    eventDesc={eventDesc}
                                    eventDate={eventDate}
                                    eventTypes={eventTypes}
                                    handleTypesCheckboxesChange={this.handleTypesCheckboxesChange} /> : ""}
                            {currentSlide === 'services' ? <ServicesForm
                                handleChange={this.handleChange}
                                handleBackButton={this.handleBackButton}
                                servicesSelected={servicesSelected}
                                handleServicesCheckboxesChange={this.handleServicesCheckboxesChange} /> : ""}
                            {currentSlide === 'submit' ?
                                <SubmitForm
                                    handleBackButton={this.handleBackButton}
                                    name={name}
                                    email={email}
                                    telephone={phone}
                                    entreprise={entreprise}
                                    eventName={eventName}
                                    eventAddress={eventAddress}
                                    eventDesc={eventDesc}
                                    eventDate={eventDate}
                                    eventTypes={eventTypes}
                                    servicesSelected={servicesSelected}
                                    handleChange={this.handleChange} /> : ""}
                            {currentSlide === 'submit' ?'':
                            <div className='btn-section'>
                                  <button id='btn-next' className='next' onClick={this.handleNext}>Suivant &nbsp;<FontAwesomeIcon icon={faArrowRight} /></button>
                                </div>
                            }
                            {emptyField ? <span className='error-message' id='error-message'>Veuillez compléter les champs obligatoires!</span> : ""}
                    </div>
                </section>
            </div>
        )
    }
}
