import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAnglesRight} from '@fortawesome/free-solid-svg-icons';
import picture1 from '../images/Picture1.jpg'
import picture2 from '../images/Picture2.jpg'
import picture4 from '../images/Picture4.jpg'
import concert from '../images/concert.png'
import forum from '../images/forum.png'
import diner from '../images/diner.png'
import inauguration from '../images/inauguration.png'
import exposition from '../images/exposition.png'
import party from '../images/party.png'
//import wedding from '../images/wedding.png'
import corporate from '../images/corporate.png'

export default function Services() {
  return (
    <div className='services'>
      <section className='title-with-background'>
        <h1>Nos services</h1>
      </section>
      <h2 className='title'>Services</h2>
      <p className='subtitle'>Découvrez les différents services que nous fournissons</p>
      <section className='section1'>
        <div className='container'>
          <div className='text-grid' data-aos="fade-right">
            <div className='cont-title'>
              <h3>Évènements</h3>
              <p>Nous vous accompagnons dans chaque étapes de la réalisation de vos évènement</p>
              <ul className='fa-ul'>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Consultation</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Création du concept</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Elaboration du Budget</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Project Management</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Aménagement et décor</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Production</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Coordination & Execution</li>
              </ul>
            </div>
          </div>
          <div className='image-grid' data-aos="fade-left">
            <div className='img-surounding'>
              <img src={picture1} alt='event' />
            </div>
          </div>
        </div>
      </section>
      <section className='section2'>
        <div className='container'>
          <div className='text-grid' data-aos="fade-left">
            <div className='cont-title'>
              <h3>Communication</h3>
              <p>Nous vous fournissons les meilleurs stratégies de communication</p>
              <ul className='fa-ul'>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Communication digital</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Communication institutionnelle</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Communication évènementiel</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Communication personnel</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Création Visuel</li>
              </ul>
            </div>
          </div>
          <div className='image-grid' data-aos="fade-right">
            <div className='img-surounding'>
              <img src={picture2} alt='event' />
            </div>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div className='container'>
          <div className='text-grid' data-aos="fade-right">
            <div className='cont-title'>
              <h3>Aménagement & Décor</h3>
              <p>Nous mettons notre créativite et notre expertisme en décoration à votre disposition </p>
              <ul className='fa-ul'>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Aménagement shop/stand</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Aménagement bureau</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Mise en place d'évènement</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Décor évènement</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Création visuel</li>
              </ul>
            </div>
          </div>
          <div className='image-grid' data-aos="fade-left">
            <div className='img-surounding'>
              <img src={picture4} alt='event' />
            </div>
          </div>
        </div>
      </section>
      {/* TODO:Check event type screen on big screens and see if height unit nee to be changed */}
      <h2 className='title'>Types d'évènement</h2>
      <p className='subtitle'>Découvrez les différents types d'évènement que nous organisons</p>
      <section className='section4'>
        <div className='container' data-aos="zoom-in">
          <div className='event-type' >
            <div className='event-icon'>
              <img src={forum} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Conférence & forums</h3>
            </div>
            <div className='event-desc'><p>Évènements qui rassemblent des experts, des professionnels et acteurs clés pour discuter sur des sujets pertinents.</p></div>
          </div>
          <div className='event-type'>
            <div className='event-icon'>
              <img src={diner} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Soirée et dîner de gala</h3>
            </div>
            <div className='event-desc'><p>Réception de caractère officiel marquée par un grand apparat.</p></div>
          </div>
          <div className='event-type'>
            <div className='event-icon'>
              <img src={exposition} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Salons d'exposition</h3>
            </div>
            <div className='event-desc'><p>Évènements ans lesquels les entreprises d'une industrie où d'un secteur spécifique se réunissent pour présenter leurs offres.</p></div>
          </div>
          <div className='event-type'>
            <div className='event-icon'>
              <img src={inauguration} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Inaugurations et lancements</h3>
            </div>
            <div className='event-desc'><p>Activités marquant les débuts d'une nouvelle entreprise, d'un nouveau produit, d'un nouveau service ou d'un nouveau lieu. </p></div>
          </div>
          <div className='event-type'>
            <div className='event-icon'>
              <img src={concert} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Concerts et festivals</h3>
            </div>
            <div className='event-desc'><p>Rassemblement des artistes, des artisans et des amateurs de divertissement dans une célébration vibrante par la musique et de l'art. .</p></div>
          </div>
          <div className='event-type'>
            <div className='event-icon'>
              <img src={party} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Évènements privés</h3>
            </div>
            <div className='event-desc'><p>Activités non annoncé ni ouvert au public, concernant uniquement les personnes participantes et invitées.</p></div>
          </div>
          {/* <div className='event-type'>
            <div className='event-icon'>
              <img src={wedding} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Mariage</h3>
            </div>
            <div className='event-desc'><p>Pariatur est aliquip laboris magna quis consequat id non.</p></div>
          </div> */}
          <div className='event-type'>
            <div className='event-icon'>
              <img src={corporate} alt='event type' />
            </div>
            <div className='event-title'>
              <h3>Événements corporatifs</h3>
            </div>
            <div className='event-desc'><p>Ces événements peuvent revêtir différentes formes, allant des réunions et des conférences aux séminaires, aux cocktails, aux galas et aux célébrations spéciales..</p></div>
          </div>
        </div>
      </section>
    </div>
  )
}
