import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import strength from '../images/strength.png';
import values from '../images/value.png';
import medal from '../images/medal.png';

export default function Apropos() {

  return (
    <div className='apropos'>
      <section className='title-with-background'>
        <h1>À propos de nous</h1>
      </section>
      <section className='section1'>
        <div className='container'>
          <h1>Qui sommes nous ?</h1>
          <p>The Bee est une agence d'évènementielle spécialisé dans la conception, la coordination et l’éxécution d’évènements corporates, publics et privés.</p>
        </div>
      </section>
      <section className='section2'>
        <div className='container'>
          <div className='grid1'>
            <h4><span>Nos Valeurs</span></h4>
            <div className='img-grid'>
              <div className='background'>
                <img src={values} alt='values' />
              </div>
            </div>
            <div className='text-grid'>
              <ul className='fa-ul'>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Créativité</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Excellence organisationnelle</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Valorisation des métiers de l'évènementiel</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Esprit d'équipe</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Transparence</li>
              </ul>
            </div>
          </div>
          <div className='grid2'>
            <h4><span>Nos points forts</span></h4>
            <div className='img-grid'>
              <div className='background'>
                <img src={strength} alt='strength' />
              </div>
            </div>
            <div className='text-grid'>
              <ul className='fa-ul'>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Grande capacité organisationnelle et structurelle</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Capacité construire et gérer des budgets importants et complexes</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Forte capacité à négocier en vue de l’obtention des prix avantageux.</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Carnet d’adresse exhaustif des prestataires de services et fournisseurs évent.</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Gestion d’équipes allant de 5 à 500 personnes</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Capacité à anticiper ou gérer des imprévus</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Conceptualisation et aménagement</li>
              </ul>
            </div>
          </div>
          <div className='grid3'>
            <h4><span>Nos Specialites</span></h4>
            <div className='img-grid'>
              <div className='background'>
                <img src={medal} alt='strength' />
              </div>
            </div>
            <div className='text-grid'>
              <ul className='fa-ul'>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Conférences et forums</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Soirées et dîners de Gala</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Festivals</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Evènements corporate annuels</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Evènements spéciaux</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Inaugurations et lancements</li>
                <li><span className="fa-li custom-list"><FontAwesomeIcon icon={faAnglesRight} /></span>Aménagements thématiques</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
