import React from 'react'
import logo from '../images/logo-sans-fond.png'

function PlaceHolder() {
    return (
        <div className='placeholder'>
            <img src={logo} alt="the bee" />
            <h1>Welcome to The Bee !</h1> 
            <p>Our bees are busy making honey</p>
            <span>Coming soon...</span>
        </div>
    )
}

export default PlaceHolder;