import { createBrowserRouter } from "react-router-dom";
import Accueille from "./Accueil";
import Apropos from "./Apropos";
import Events from "./Events";
import Services from "./Services";
import News from "./News";
import NotFound from "./NotFound";
import Formulaire from "./Formulaire/Formulaire";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Accueille/>
    },
    {
        path: '/apropos',
        element:<Apropos/>
    },
    {
        path: '/news',
        element:<News/>
    },
    {
        path: '/services',
        element:<Services/>
    },
    {
        path: '/events',
        element:<Events/>
    },
    {
        path: '/customer/form',
        element: <Formulaire />
    },
    {
        path: '*',
        element: <NotFound/>
    }
    
])

export default router;