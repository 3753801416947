import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import FadeLoader from "react-spinners/FadeLoader";
import successIcon from '../../images/success.png';
import failIcon from '../../images/fail.png';


const eventsMap = {
    "private": "Privé",
    "entreprise": "entreprise",
    "conf-forum": "Conférence et forum",
    "festival": "Festival",
    "salon": "Salon",
    "concert": "Concert",
    "mariage": "Mariage",
    "gala": "Diner de Gala",
    "night": "Cocktail ou soirée",
    "conference": "Conférence de presse",
    "inoguration": "Inauguration et lancement",
    "birthday": "Soirée privée ou anniversaire",
    "autres": "Autres types d'évènement"
}

const servicesMap = {
    "create-concept": "Création du concept",
    "budget": "Budgetisation",
    "caisse": "Plan de décaissements et paiements",
    "cordination": "Coordination générale",
    "recrutement": "Recrutement et négociations prestataires",
    "communication": "Gestion de la communication",
    "deco": "Aménagement et décoration",
    "gest-accueil": "Gestion accueil et hotesses",
    "photo-vid": "Couverture videos et photos",
    "event": "Execution de l'évènemment",
    "travel": "Gestion logistique voyage et hébergement",
    "technique": "Gestion technique",
    "traiteur": "Gestion et proposition traiteur",
    "autres": "Autres services"
}
let eventsStringArr = [];
let servicesStringArr = [];
let eventsString = "";
let servicesString = "";

export default function SubmitForm(props) {
    const name = props.name;
    const email = props.email;
    const telephone = props.telephone;
    const entreprise = props.entreprise;
    const eventName = props.eventName;
    const eventDate = props.eventDate;
    const eventAddress = props.eventAddress;
    const eventDesc = props.eventDesc;
    const eventTypes = props.eventTypes;
    const handleBackButton = props.handleBackButton;
    const servicesSelected = props.servicesSelected;

    if (eventTypes.length !== 0) {
        console.log(eventTypes.length);
        for (let i = 0; i < eventTypes.length; i++) {
            eventsStringArr.push(eventsMap[eventTypes[i]])
        }
        eventsStringArr = [...new Set(eventsStringArr)]
        eventsString = eventsStringArr.toString()
    } else {
        eventsString = "Autres"
    }

    if (servicesSelected.length !== 0) {
        for (let j = 0; j < servicesSelected.length; j++) {
            servicesStringArr.push(servicesMap[servicesSelected[j]])
        }
        servicesStringArr = [...new Set(servicesStringArr)]
        servicesString = servicesStringArr.toString()
    } else {
        servicesString = "Autres"
    }

    const form = useRef();

    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    const sendEmail = (e) => {
        console.log("function called")
        e.preventDefault();
        setStatus("loading");
        emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_TEMPLATE, form.current, process.env.REACT_APP_YOUR_PUBLIC_KEY)
            .then((result) => {
                //console.log(result.text);
                if (result.text === "OK") {
                    setStatus("success");
                    setTimeout(() => {
                        setStatus(null);
                    }, 3000);
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 5000);
                }
            }, (error) => {
                setError("Une erreur est survenu lors de l'envoi de votre formulaire");
                setStatus("error");
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
                console.log(error.text);
            });
    }

    return (
        <div className='form-container'>
            <div className='btn-section back'>
                <button onClick={handleBackButton}><FontAwesomeIcon icon={faArrowLeft} />&nbsp; Retour</button>
            </div>
            <form ref={form} onSubmit={sendEmail}>
                <div className='input-section submit-form'>
                    {status === null ? <label>Confirmer les informations</label> : ''}
                    {/* {error !== null ? <p className='error'>{error}</p> : ''} */}
                    {status === null ? <ul>
                            <li>
                                <h5>Nom: </h5>
                                <span>{name}</span>
                                <input type='hidden' value={name} name='name' />
                            </li>
                            <li>
                                <h5>Adresse e-mail: </h5>
                                <span>{email}</span>
                                <input type='hidden' value={email} name='email' />
                            </li>
                            <li>
                                <h5>Numéro de téléphone: </h5>
                                <span>{telephone}</span>
                                <input type='hidden' value={telephone} name='telephone' />
                            </li>
                            {entreprise !== "" ?
                                <li>
                                    <h5>Entreprise: </h5>
                                    <span>{entreprise}</span>
                                    <input type='hidden' value={entreprise} name='entreprise' />
                                </li>
                                : ""}
                            <li>
                                <h5>Nom de l'évènement: </h5>
                                <span>{eventName}</span>
                                <input type='hidden' value={eventName} name='event-name' />
                            </li>
                            <li>
                                <h5>Date de l'évènement: </h5>
                                <span>{eventDate}</span>
                                <input type='hidden' value={eventDate} name='event-date' />
                            </li>
                            {eventAddress !== "" ?
                                <li>
                                    <h5>Lieu de l'évènement: </h5>
                                    <span>{eventAddress}</span>
                                    <input type='hidden' value={eventAddress} name='event-address' />
                                </li>
                                : ""}
                            <li>
                                <h5>Description de l'évènement: </h5>
                                <span>{eventDesc}</span>
                                <input type='hidden' value={eventDesc} name='event-desc' />
                            </li>
                            <li>
                                <h5>Types d'évènement: </h5>
                                <span>{eventsString}</span>
                                <input type='hidden' value={eventsString} name='event-types' />
                            </li>
                            <li>
                                <h5>Services sollicités: </h5>
                                <span>{servicesString}</span>
                                <input type='hidden' value={servicesString} name='services' />
                            </li>
                        </ul>:
                        <div className='status-container'>
                            {status === "success" ?
                                <div className='success-container'>
                                    <p className='success'>Votre formulaire a été envoyé avec succcés</p>
                                    <img src={successIcon} alt="success"></img>
                                </div> : ""}
                            {status === "loading" ?
                                <div className='loading-container'>
                                    <p className='loading'>Loading...</p>
                                    <FadeLoader color="#deaf39" />
                                </div> : ""}
                            {error !== null ?
                                <div className='error-container'>
                                    <p className='error error-message'>{error}</p>
                                    <img src={failIcon} alt="error"></img>
                                </div> : ""}
                        </div>}
                    <div className='btn-section'>
                        {status === null ? <input type="submit" value='Soumettre le formulaire' className='next' /> : ''}
                    </div>
                </div>
            </form>
        </div>
    )
}
