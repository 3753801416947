import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function EventForm(props) {
    const handleChange = props.handleChange;
    const eventName = props.eventName;
    const eventDate = props.eventDate;
    const eventAddress = props.eventAddress;
    const eventDesc = props.eventDesc;
    const handleTypesCheckboxesChange = props.handleTypesCheckboxesChange;
    const handleBackButton = props.handleBackButton;
    const eventTypes = props.eventTypes;

    return (
        <div className='form-container'>
            <div className='btn-section back'>
                <button onClick={handleBackButton}><FontAwesomeIcon icon={faArrowLeft} />&nbsp; Retour</button>
            </div>
            <div className='input-section'>
                <label>Nom de l'évènement*</label>
                <input type='text' name='eventName' onChange={handleChange} value={eventName} />
            </div>
            <div className='input-section'>
                <label>Date de l'évènement*</label>
                <input type='date' name='eventDate' onChange={handleChange} value={eventDate} />
            </div>
            <div className='input-section'>
                <label>Lieu de l'évènement</label>
                <input type='text' name='eventAddress' onChange={handleChange} value={eventAddress} />
            </div>
            <div className='input-section'>
                <label>Veuillez nous décrire l'évènement que vous voudriez organiser*</label>
                <textarea name='eventDesc' onChange={handleChange} value={eventDesc}></textarea>
            </div>
            <div className='input-section'>
                <label className='checkboxes-title'>Types d'évènement</label>
                <div className='checkbox-section'>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="private">Privé</label>
                        { eventTypes.includes("private") ? <input type="checkbox" value="private" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="private" onChange={handleTypesCheckboxesChange}/> }
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="entreprise">Entreprise</label>
                        {eventTypes.includes("entreprise") ? <input type="checkbox" value="entreprise" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="entreprise" onChange={handleTypesCheckboxesChange}/>}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="conf-forum">Conférence et forum</label>
                        {eventTypes.includes("conf-forum") ? <input type="checkbox" value="conf-forum" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="conf-forum" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="festival">Festival</label>
                        {eventTypes.includes("festival") ? <input type="checkbox" value="festival" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="festival" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="salon">Salon</label>
                        {eventTypes.includes("salon") ? <input type="checkbox" value="salon" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="salon" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="concert">Concert</label>
                        {eventTypes.includes("concert") ? <input type="checkbox" value="concert" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="concert" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="mariage">Mariage</label>
                        {eventTypes.includes("mariage") ? <input type="checkbox" value="mariage" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="mariage" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="gala">Diner de Gala</label>
                        {eventTypes.includes("gala") ? <input type="checkbox" value="gala" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="gala" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="night">Cocktail ou soirée</label>
                        {eventTypes.includes("night") ? <input type="checkbox" value="night" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="night" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="conference">Conférence de presse</label>
                        {eventTypes.includes("conference") ? <input type="checkbox" value="conference" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="conference" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="inoguration">Inauguration et lancement</label>
                        {eventTypes.includes("inoguration") ? <input type="checkbox" value="inoguration" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="inoguration" onChange={handleTypesCheckboxesChange}  />}
                    </div>
                    <div className='checkbox-wrapper'>
                        <label htmlFor="autres">Autres types d'évènement</label>
                        {eventTypes.includes("autres") ? <input type="checkbox" value="autres" onChange={handleTypesCheckboxesChange} checked /> : <input type="checkbox" value="autres" onChange={handleTypesCheckboxesChange} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
