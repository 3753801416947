import React from 'react'

export default function ContactForm(props) {
    const handleChange = props.handleChange;
    const name = props.name;
    const email = props.email;
    const telephone = props.telephone;
    const entreprise = props.entreprise;

    return (
        <div className='form-container'>
            <div className='input-section'>
                <label>Nom complet*</label>
                <input type='text' name='name' onChange={handleChange} value={name}></input>
            </div>
            <div className='input-section'>
                <label>Addresse email*</label>
                <input type='email' name='email' onChange={handleChange} value={email}></input>
            </div>
            <div className='input-section'>
                <label>Numéro de téléphone*</label>
                <input type='text' name='phone' onChange={handleChange} value={telephone}></input>
            </div>
            <div className='input-section'>
                <label>Entreprise</label>
                <input type='text' name='entreprise' onChange={handleChange} value={entreprise}></input>
            </div>
        </div>
    )
}
