import React from 'react'
import data from '../assets/data.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function Events() {

  const soireeGala = [];
  const festivals = [];
  const privateEvent = [];
  const forums = [];
  const expositions = [];
  const conferences = [];
  const corporates = [];
  const inoguration = [];
  const special = [];

  data.events.forEach((event, index) => {
    let event_html = (
      <div className='event-card' key={index}>
        <div className='img-grid'>
          <img src={event.img} alt='event'></img>
        </div>
        <div className='desc-grid'>
          <div className='name'>
            <h5>{event.name}</h5>
          </div>
          <span className='date'>{event.date}</span>
          <p className='desc'>{event.client}</p>
        </div>
      </div>
    )

    if (event.category === "soiree-gala") {
      soireeGala.push(event_html)
    } else if (event.category === "private") {
      privateEvent.push(event_html)
    } else if (event.category === "festival") {
      festivals.push(event_html)
    } else if (event.category === "forum") {
      forums.push(event_html)
    } else if (event.category === "conference") {
      conferences.push(event_html)
    } else if (event.category === "exposition") {
      expositions.push(event_html)
    } else if (event.category === "corporate") {
      corporates.push(event_html)
    } else if (event.category === "inoguration") {
      inoguration.push(event_html)
    } else if (event.category === "special") {
      special.push(event_html)
    }
  });

  const scrollLeft = (element) => {
    document.getElementById(element).scrollLeft -= 440;
  }
  const scrollRight = (element) => {
    document.getElementById(element).scrollLeft += 440;
    //console.log(document.getElementById(element));
  }

  return (
    <div className='events'>
      <section className='title-with-background'>
        <h1>Nos évènements</h1>
      </section>
      <h2 className='title'>Évènements</h2>
      <p className='subtitle'>Découvrez les différents Évènements que nous avons organisés</p>
      <section className='section1'>
        <div className='container'>
          <h4>SOIRÉES ET DÎNERS DE GALA</h4>
          <div className='category soiree-gala' id="gala">
            <div className="scroll-arrow left" onClick={() => scrollLeft("gala")}><button><FontAwesomeIcon icon={faArrowLeft} /></button></div>
            {soireeGala}
            <div className="scroll-arrow right" onClick={() => scrollRight("gala")}><button><FontAwesomeIcon icon={faArrowRight} /></button></div>
          </div>
          <h4>CONFÉRENCES & FORUMS</h4>
          <div className='category forum conference' id="conference">
            <div className="scroll-arrow left" onClick={() => scrollLeft("conference")}><button><FontAwesomeIcon icon={faArrowLeft} /></button></div>
            {forums}
            {conferences}
            <div className="scroll-arrow right" onClick={() => scrollRight("conference")}><button><FontAwesomeIcon icon={faArrowRight} /></button></div>
          </div>
          <h4>ÉVÈNEMENTS CORPORATE</h4>
          <div className='category corporate' id="corporate">
            <div className="scroll-arrow left" onClick={() => scrollLeft("corporate")}><button><FontAwesomeIcon icon={faArrowLeft} /></button></div>
            {corporates}
            <div className="scroll-arrow right" onClick={() => scrollRight("corporate")}><button><FontAwesomeIcon icon={faArrowRight} /></button></div>
          </div>
          <h4>FESTIVALS & CONCERTS</h4>
          <div className='category festival' id="festival">
            <div className="scroll-arrow left" onClick={() => scrollLeft("festival")}><button><FontAwesomeIcon icon={faArrowLeft} /></button></div>
            {festivals}
            <div className="scroll-arrow right" onClick={() => scrollRight("festival")}><button><FontAwesomeIcon icon={faArrowRight} /></button></div>
          </div>
          <h4>INAUGURATION ET LANCEMENT </h4>
          <div className='category inoguration' id="inoguration">
            <div className="scroll-arrow left" onClick={() => scrollLeft("inoguration")}><button><FontAwesomeIcon icon={faArrowLeft} /></button></div>
            {inoguration}
            <div className="scroll-arrow right" onClick={() => scrollRight("inoguration")}><button><FontAwesomeIcon icon={faArrowRight} /></button></div>
          </div>
          <h4>SPECIAL EVENTS </h4>
          <div className='category special' id="special">
            {special}
          </div>
          <h4>EXPOSITION ET FOIRES </h4>
          <div className='category exposition' id="exposition">
            {expositions}
          </div>
          <h4>PRIVATE EVENTS</h4>
          <div className='category private' id="private">
            {privateEvent}
          </div>
        </div>
      </section>
    </div>
  )
}
