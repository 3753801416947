import React, { useState, useEffect } from 'react'
import FactsCTA from './FactsCTA';
import ladystalk_pic from '../images/ladystalk-event.png';
import ena from '../images/ena.jpg'
import hein from '../images/hein.jpg'
import music from '../images/music.jpg'
import artistic from '../images/artistic.png'
import collab from '../images/collab.png'
import idea from '../images/idea.png'
import marketing from '../images/marketing.png'
import FadeLoader from "react-spinners/FadeLoader";
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';

let listOfLogos = [];
let half = [];
let firstHalf = [];
let secondHalf = [];

export default function Accueil() {
  const pics = [ena, hein, music];

  const [isLoaded, setIsLoaded] = useState(false);

  const importAll = (r) => {
    return r.keys().map(r);
  }

  useEffect(() => {
    //console.log("loading");
    const loadImages = async () => {
      listOfLogos = await importAll(require.context('../images/partners', false, /\.(png|jpe?g|svg|gif)$/));
      half = Math.ceil(listOfLogos.length / 2);
      firstHalf = listOfLogos.slice(0, half);
      secondHalf = listOfLogos.slice(half);
      setIsLoaded(true);
      //console.log("loaded");
    }
    loadImages();
  }, [])

  return (
    <div className='accueil'>
      <section className='section1'>
        <a href='/customer/form' className="postit">
          <div className="left"></div>
          <div className="middle">
            <span><FontAwesomeIcon icon={faCalendarDays} /><br/>Votre Event</span>
          </div>
          <div className="right"></div>
        </a>
        <Swiper
          className='background'
          modules={[Pagination, Autoplay]}
          spaceBetween={1}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000 }}
          //onSwiper={(swiper) => console.log(swiper)}
          //onSlideChange={() => console.log('slide change')}
          style={{
            "--swiper-pagination-color": "#deaf39",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "0.5",
            "--swiper-pagination-bullet-size": "12px",
            "--swiper-pagination-bullet-horizontal-gap": "10px"
          }}
        >
          {
            pics.map(
              (image, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={image} alt="home pic" />
                  </SwiperSlide>
                )
              }
            )
          }
        </Swiper>
        <div className='border-left'></div>
        <div className='welcome-title'>
          <h1>Bienvenue<br />
            Chez The Bee</h1>
          <h3>Agence de communication<br />et événementiel </h3>
        </div>
      </section>
      <FactsCTA />
      <section className='section2'>
        <div className='container'>
          <div className='img-grid'>
            <div className='img-surounding' data-aos="fade-right">
              <img src={ladystalk_pic} alt="space" />
            </div>
          </div>
          <div className='text-grid' data-aos="fade-left">
            <h4><span>Notre experstise</span></h4>
            <h1>Créer des événements incroyables</h1>
            <p>Nous sommes spécialisés dans la conception, la coordination et l’exécution d’évènements corporates, publics et privés</p>
            <div className='wrapper'>
              <div className='speciality'>
                <img src={collab} alt='speciality icon' />
                <div className='spec-text'>
                  <h5>COORDINATION ÉVÈNEMENTIELLE</h5>
                  <span>Nous étudions, panifions, et exécutons toutes les opérations liés au bon déroulement de votre évènement.</span>
                </div>
              </div>
              <div className='speciality'>
                <img src={marketing} alt='speciality icon' />
                <div className='spec-text'>
                  <h5>COMMUNICATION ÉVÈNEMENTIELLE</h5>
                  <span>Nous utilisons les techniques de communication les plus modernes et les plus efficaces selon l’environnement pour votre évènement.</span>
                </div>
              </div>
              <div className='speciality'>
                <img src={idea} alt='speciality icon' />
                <div className='spec-text'>
                  <h5>CRÉATION DE CONCEPT ÉVÈNEMENTIEL</h5>
                  <span>Nous conceptualisations votre évènement, à partir de vos idées en y ajoutant notre expertise.</span>
                </div>
              </div>
              <div className='speciality'>
                <img src={artistic} alt='speciality icon' />
                <div className='spec-text'>
                  <h5>DIRECTION ARTISTIQUE</h5>
                  <span>Nous nous chargeons de créer et de veiller à la mise en place de chaque éléments graphique et visuel pour votre évènement.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div className='container'>
          <h4><span>Nos clients et partenaires</span></h4>
          {isLoaded ?
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{ delay: 5000 }}
              //onSwiper={(swiper) => console.log(swiper)}
              //onSlideChange={() => console.log('slide change')}
              style={{
                "--swiper-pagination-color": "#deaf39",
                "--swiper-pagination-bullet-inactive-color": "#999999",
                "--swiper-pagination-bullet-inactive-opacity": "0.5",
                "--swiper-pagination-bullet-size": "14px",
                "--swiper-pagination-bullet-horizontal-gap": "10px"
              }}
            >
              <SwiperSlide>
                <div className='partners'>
                  {
                    firstHalf.map(
                      (image, index) => <img key={index} src={image} alt="partner"></img>
                    )
                  }
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='partners'>
                  {
                    secondHalf.map(
                      (image, index) => <img key={index} src={image} alt="partner"></img>
                    )
                  }
                </div>
              </SwiperSlide>
            </Swiper>
            : <div className='partners'>
              <FadeLoader color="#deaf39" />
            </div>}
        </div>
      </section>
    </div >

  )

}
