import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';


export default function Social() {

    return (
        <div className = 'social'>
            <div className='social-details col'>
                <span className='icon'><FontAwesomeIcon icon={faPhone} /></span>
                <a href="tel:+243 852 377 055">+243 852 377 055</a>
            </div>
            <div className='social-details col'>
                <span className='icon'><FontAwesomeIcon icon={faEnvelope} /></span>
                <a href="mailto:thebeerdc@gmail.com">thebeerdc@gmail.com</a>
            </div>
            <div className='social-media col'>
                <ul>
                    <li><a href="https://www.facebook.com/thebeerdc" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
                    <li><a href="https://www.linkedin.com/company/the-bee/about/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                    {/*<li><a href="#" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li>*/}
                    <li><a href="https://www.instagram.com/thebeerdc/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></li>
                </ul>
            </div>
        </div>
    )
}
